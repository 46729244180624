.editor-container {
  display: flex;
  transition: all 2s ease;
  overflow: hidden;
}

.editor-section-header {
  display: flex;
  flex-direction: row;
  gap: 10px;
  margin-top: 64px;
}

.section-container {
  padding-bottom: 1vw;
  padding-left: 1vw;
  padding-right: 1vw;
  margin-top: 12px;
  /* background: #ffffff; */
  box-shadow: 0px 0px 10px rgba(132, 131, 169, 0.22);
  border-radius: 4px;
  font-size: calc(10px + 0.4vw);
}

.pdf-section-container {
  margin-top: 12px;
  background: #ffffff;
  box-shadow: 0px 0px 10px rgba(132, 131, 169, 0.22);
  border-radius: 10px;
  font-size: calc(10px + 0.4vw);
}

.pdf-section-header {
  text-align: center;
  display: flex;
  flex-direction: row;
  gap: 10px;
}


.editor-section,
.pdf-section {
  overflow: hidden;
}

.editor-section {
  transition: all 0.5s ease;
  display: flex;
  flex-direction: column;
  padding: 20px;
}

.pdf-section {
  transition: all 0.5s ease;
  display: flex;
  flex-direction: column;
  padding: 20px;
}

.editor-container.split .editor-section {
  display: flex;
  width: 60%;
  
}

.editor-container.split .pdf-section {
  display: flex;
  width: 40%;
}

@media (max-width: 768px) {
}
