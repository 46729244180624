.review-container {
  display: flex;
  transition: all 2s ease;
  overflow: hidden;
}

.review-section {
  display: flex;
  flex-direction: column;
  gap: 16px;
  transition: all 0.5s ease; 
  overflow: hidden;
  padding: 20px;
}

.pdf-review-section {
  display: flex;
  flex-direction: column;
  gap: 16px;
  transition: all 0.5s ease; 
  overflow: hidden;
  padding: 20px;
}


.review-container.split .review-section {
  display: flex;
  width: 55%;  
}

.review-container.split .pdf-review-section {
  display: flex;
  width: 45%;
}


@media (max-width: 768px) {
}
