.query-container {
  display: flex;
  transition: all 2s ease;
  overflow: hidden;
}

.query-section {
  transition: all 0.5s ease;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-top: 24px;
  padding-right: 24px;
  padding-left: 24px;
}

.pdf-section-header {
  text-align: center;
  display: flex;
  flex-direction: row;
  gap: 10px;
}

.query-pdf-section {
  display: flex;
  flex-direction: column;
  gap: 16px;
  transition: all 0.5s ease; 
  overflow: hidden;
}

.query-container.split .query-section {
  display: flex;
  width: 65%;  
}

.query-container.split .query-pdf-section {
  display: flex;
  width: 35%;
}

.chat-recommendation {
  display: flex;
  text-align: center;
  align-items: center;
  max-width: 200px;
  padding: 16px;
  border-width: 1px;
  border-style: 'solid';
  border-radius: 16px;
  box-shadow: 0px 0px 10px rgba(132, 131, 169, 0.22);
  font-size: calc(8px + 0.4vw);
  color: rgb(56, 55, 55);
}

@media (max-width: 768px) {
}
