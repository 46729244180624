body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
    'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.blue {
  color: #1890ff;
}

.header1 {
  font-weight: 700;
  font-size: 2vw;
}

.header2 {
  font-size: 1.5vw;
  font-weight: 600;
}

.header3 {
  font-size: 1vw;
  font-weight: 500;
}

.flex-column {
  display: flex;
  flex-direction: column
}

.flex-row {
  display: flex;
  flex-direction: row
}

.scrollable {
  overflow: 'auto'
}