.feedback-container {
    display: flex;
    /* justify-content: center; */
    align-items: center;
    gap: 10px;
  }
  
.thumb {
    cursor: pointer;
    /* padding: 10px; */
    transition: transform 0.2s ease, color 0.2s ease;
    color: grey;
}

.thumb:hover {
    transform: scale(1.1); /* Hover effect to enlarge */
}

.thumb:active {
    transform: scale(0.9); /* Click effect to shrink */
}

.active.thumb-up {
    color: green; /* Change color when active */
    transform: scale(1.2); /* Scale up the icon when selected */
}
  
.active.thumb-down {
    color: red; /* Change color when active */
    transform: scale(1.2); /* Scale up the icon when selected */
}
  