.dropdown-product-container {
  text-align: center;
}

.highlight {

}

.highlight :hover {
  background-color: blue;
}