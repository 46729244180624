.dropdown-product-container {
  text-align: center;
}

.upload-images-icon {
  display: flex;
  border-radius: 50%;
  background-color: #a3d3a3;
  box-shadow: 0 2px 5px #bebebe;
  width: 40px;
  height: 40px;
  align-items: center;
  justify-content: center;
}

.delete-icon {
  display: flex;
  border-radius: 50%;
  background-color: #e94242;
  box-shadow: 0 2px 5px #bebebe;
  width: 40px;
  height: 40px;
  align-items: center;
  justify-content: center;
}
